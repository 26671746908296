import React, { useCallback, useEffect, useState, useRef } from 'react';
import cls from 'classnames';
import { getWidth } from 'dom-lib';
import onResize, { unbind } from 'element-resize-event';

import useLoop from 'utils/useLoop';
import Fade from 'components/Fade';
import VideoPlayer from 'components/VideoPlayer';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'light', list }) => {
  const len = (list || []).length;
  const contentWidth = 640 + 440 * (len - 1);
  const [curr, { goto: setCurr, play, pause }] = useLoop(0, { totalSize: len });
  const [show, setShow] = useState(false); // 是否展示视频播放器
  const [url, setUrl] = useState(''); // 当前视频播放地址
  const slidesWrapperEl = useRef(null);
  const slidesEl = useRef(null);
  const transHandler = useCallback(() => {
    const wrapperWidth = getWidth(slidesWrapperEl.current);
    const transX =
      wrapperWidth >= contentWidth || curr === 0
        ? 0
        : curr === 1
        ? (wrapperWidth - contentWidth) / 2
        : wrapperWidth - contentWidth;

    if (slidesEl.current) {
      slidesEl.current.style.transform = `translateX(${transX}px)`;
    }
  }, [contentWidth, curr]);

  // handler
  const playHandler = useCallback((video) => {
    setUrl(video);
    setShow(true);
  }, []);
  const closeHandler = useCallback(() => {
    setUrl('');
    setShow(false);
  }, []);

  // 监听 页面大小调整
  useEffect(() => {
    onResize(document.body, transHandler);

    return () => unbind(document.body, transHandler);
  }, [transHandler]);

  // curr 变动，计算偏移
  useEffect(transHandler, [transHandler]);

  return (
    <div id="scale-carousel" className={cls([styles.container, bgColor])}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>
        <div ref={slidesWrapperEl} className={styles.slidesWrapper}>
          <div
            ref={slidesEl}
            className={styles.slides}
            style={{ width: contentWidth }}
          >
            {(list || []).map(
              ({ src, itemStyle, title, content, tip, video }, i) => {
                return (
                  <div key={title} className={styles.slideWrapper}>
                    <div
                      className={cls([styles.slide, i === curr && 'active'])}
                      onMouseEnter={() => {
                        setCurr(i);
                        pause();
                      }}
                      onMouseLeave={() => {
                        play();
                      }}
                    >
                      <img
                        src={src}
                        style={itemStyle}
                        className={styles.slideCover}
                      />
                      <div className={styles.slideMask} />
                      <div className={styles.slideContent}>
                        <div className={styles.slideCTitle}>{title}</div>
                        <div
                          className={cls([
                            styles.slideCContent,
                            !video && 'extend',
                          ])}
                        >
                          {(content || []).map((c, i) => (
                            <p key={i}>{c}</p>
                          ))}
                        </div>
                        <div className={styles.slideCTip}>{tip}</div>
                        {video ? (
                          <div
                            className={styles.slideCVideo}
                            onClick={(e) => {
                              e.stopPropagation();
                              playHandler(video);
                            }}
                          >
                            <img
                              style={{ width: 50 }}
                              src={require('images/icon-play.png')}
                            />
                            <div className={styles.slideCVTip}>
                              观看阶段视频
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <h3 className={styles.slideWrapperTitle}>{title}</h3>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>

      <Fade in={show}>
        <VideoPlayer src={url} onClose={closeHandler} />
      </Fade>
    </div>
  );
};
