import React, { Component, Fragment } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import RulesAlert from 'components/RulesAlert';

import styles from './style.module.less';

class FormBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      school: '',
      userName: '',
      checkRules: true,
      showModal: false,
      phone: '',
      phoneCode: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPhoneCode = this.getPhoneCode.bind(this);
  }

  getPhoneCode() {
    if (/^1[3456789]\d{9}$/.test(this.state.phone)) {
      axios
        .post(
          `${process.env.API_DOMAIN}/webapi/sendPotentialUserVerifyCode`,
          { accountNo: this.state.phone },
          {
            headers: {
              Authorization:
                'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
            },
          }
        )
        .then((res) => {
          if (res.data.status === 0) {
            alert('验证码已发送！');
          } else {
            alert('验证码发送失败，请重试！');
          }
        });
    } else {
      alert('手机号格式不正确！');
    }
  }

  changeSchool(e) {
    e.preventDefault();
    this.setState({
      school: e.currentTarget.value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.checkRules) {
      alert('请仔细阅读并勾选用户协议！');
      return;
    }
    if (
      !this.state.school ||
      !this.state.userName ||
      !this.state.phone ||
      !this.state.phoneCode
    ) {
      alert('表单填写不完整！');
      return;
    }
    if (!/^1[3456789]\d{9}$/.test(this.state.phone)) {
      alert('手机号不正确！');
      return;
    }
    if (!/^\d{6}$/.test(this.state.phoneCode)) {
      alert('验证码不正确！');
      return;
    }

    axios
      .post(
        `${process.env.API_DOMAIN}/webapi/addPotentialUser`,
        {
          schoolId: this.state.school * 1,
          realName: this.state.userName,
          mobilePhone: this.state.phone,
          verifyCode: this.state.phoneCode,
          instrumentInterest: [this.props.instrument === 'piano' ? 6 : 5],
          customerTypes: [this.props.customer === 'child' ? 1 : 2],
          source: this.props.source || '8',
        },
        {
          headers: {
            Authorization:
              'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
          },
        }
      )
      .then((res) => {
        if (res.data.status === 0) {
          this.props.onSuccess?.();
        } else {
          alert(`预约失败！原因：${res.data.msg}`);
        }
      });
  }

  render() {
    const { landingImage, title, description, customer } = this.props;
    const { checkRules, showModal } = this.state;

    return (
      <Fragment>
        <div className={styles.container}>
          <RulesAlert
            showModal={showModal}
            closeModal={() => this.setState({ showModal: false })}
          />
          <div className={styles.formSec}>
            <div className={styles.formSecWrapper}>
              <h2>{title}</h2>
              <p>{description}</p>
              <form onSubmit={this.handleSubmit}>
                <div className={styles.formItem}>
                  <select
                    className={styles.ipt}
                    name="school"
                    value={this.state.school}
                    onChange={this.changeSchool.bind(this)}
                  >
                    <option value="" hidden>
                      选择校区
                    </option>
                    <option value={6}>西溪湿地总校</option>
                    <option value={5}>中大银泰校区</option>
                    <option value={7}>大悦城校区</option>
                    <option value={8}>黄龙旗舰校区</option>
                    <option value={11}>西溪印象城校区</option>
                    <option value={15}>
                      绿城指教社区音乐中心（杨柳郡校区）
                    </option>
                  </select>
                </div>
                <div className={styles.formItem}>
                  <input
                    className={styles.ipt}
                    type="text"
                    name="userName"
                    placeholder={
                      customer === 'child'
                        ? '请输入孩子的姓名'
                        : '请输入您的姓名'
                    }
                    maxLength="20"
                    value={this.state.userName}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={styles.formItem}>
                  <input
                    className={cls([styles.ipt, styles.iptWidth])}
                    type="text"
                    name="phone"
                    placeholder="请输入您的手机号"
                    maxLength="11"
                    value={this.state.phone}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={styles.formItem}>
                  <div
                    className={cls([
                      styles.ipt,
                      styles.iptWidth,
                      styles.iptWrapper,
                    ])}
                  >
                    <input
                      type="text"
                      maxLength="6"
                      name="phoneCode"
                      placeholder="请输入手机验证码"
                      value={this.state.phoneCode}
                      onChange={this.handleInputChange}
                    />
                    <div onClick={this.getPhoneCode}>获取验证码</div>
                  </div>
                </div>
                <div>
                  <button
                    className={cls({
                      [styles.disabled]: !checkRules,
                    })}
                    type="submit"
                  >
                    免费预约
                  </button>
                </div>
                <div className={styles.notice}>
                  <input
                    type="checkbox"
                    checked={checkRules}
                    onChange={(e) =>
                      this.setState({ checkRules: e.target.checked })
                    }
                  />
                  <span>
                    我已知晓并接受
                    <a
                      href="javascript:;"
                      onClick={() => this.setState({ showModal: true })}
                    >
                      《个人信息收集使用规则》
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
          <div
            className={styles.bg}
            style={{ backgroundImage: `url("${landingImage}")` }}
          />
        </div>
      </Fragment>
    );
  }
}

FormBanner.propTypes = {
  landingImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  instrument: PropTypes.oneOf(['piano', 'voice']).isRequired,
  customer: PropTypes.oneOf(['adult', 'child']).isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func,
};

FormBanner.defaultProps = {
  onSuccess: function () {},
};

export default FormBanner;
