import React, { Fragment } from 'react';
import cls from 'classnames';

import useLoop2 from 'utils/useLoop2';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'dark', list }) => {
  const len = (list || []).length;
  const [curr, icurr, { goto, play, pause }] = useLoop2([0, 0], {
    totalSize: [len, (list || []).map((x) => (x.steps || []).length)],
  });

  return (
    <div id="acc-carousel" className={cls([styles.container, bgColor])}>
      <div className={styles.wrapper}>
        <div className={styles.infoWrapper}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.desc}>{description}</p>
        </div>

        <div className={styles.slides}>
          {(list || []).map(({ src, itemStyle, title, desc, steps }, i) => {
            return (
              <div
                key={'banner' + i}
                className={cls([styles.slide, i === curr && 'active'])}
                onMouseEnter={() => {
                  goto(i, 0);
                }}
              >
                <div className={styles.slideCover}>
                  <img src={src} style={itemStyle} />
                </div>

                <div className={styles.slideMask} />
                <div className={styles.slideContent}>
                  <h3 className={styles.slideCTitle}>{title}</h3>
                  <div className={styles.slideCDesc}>{desc}</div>
                  <div className={styles.stepsWrapper}>
                    <div className={styles.steps}>
                      {(steps || []).map(({ label }, i) => (
                        <Fragment key={i}>
                          <div
                            className={cls([
                              styles.step,
                              i === icurr && 'active',
                            ])}
                          >
                            <div
                              className={styles.stepCover}
                              onMouseEnter={() => {
                                pause();
                                goto(undefined, i);
                              }}
                              onMouseLeave={() => play()}
                            >
                              <img
                                style={{ width: 58, height: 58 }}
                                src={
                                  icurr === i
                                    ? require('images/icon-kk.png')
                                    : require('images/icon-kk-outlet.png')
                                }
                              />
                              <div className={styles.stepCoverTitle}>
                                {label}
                              </div>
                            </div>
                            {i + 1 !== steps.length ? (
                              <div className={styles.stepArrowWrapper}>
                                <img
                                  className={styles.stepArrow}
                                  style={{ width: 11, height: 20 }}
                                  src={require('images/icon-arrow-r.png')}
                                />
                              </div>
                            ) : null}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                    <div className={styles.stepContentsWrapper}>
                      <div
                        className={styles.stepContents}
                        style={{ transform: `translate(${-100 * icurr}%)` }}
                      >
                        {(steps || []).map(({ content }, i) => (
                          <div key={i} className={styles.stepContent}>
                            {content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
