import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header';
import Footer from 'components/Footer';
import FormBanner from 'components/FormBanner';
import ScaleCarousel from 'components/ScaleCarousel';
import AccCarousel from 'components/AccCarousel';
import CardList from 'components/CardList';
import TeachEnv from 'components/TeachEnv';
import BookFrom from 'components/BookForm';
import FixedBtn from 'components/FixedBtn';
import useCR from 'utils/useCR';
import useSource from 'utils/useSource';
import track from 'utils/track';

export default ({ location: { search } }) => {
  const cr = useCR(search);
  const source = useSource(search, '8');

  // pv 埋点
  useEffect(() => {
    track().pv('【指教音乐官网】成人声乐——页面访问');
  }, []);

  if (cr) {
    return (
      <iframe
        style={{ border: 'none' }}
        width={window.innerWidth}
        height={window.innerHeight}
        src="https://www.finger66.com/activity/teacher-join/#/?source=410000"
      />
    );
  }

  return (
    <div>
      <Header instrument="voice" customer="adult" />
      <Helmet>
        <title>Finger-成人声乐1V1</title>
      </Helmet>
      <FormBanner
        title="免费预约价值"
        description="499元的成人零基础声乐课"
        landingImage={require('images/voice/adult/bg.png')}
        instrument="voice"
        customer="adult"
        source={source}
        onSuccess={() => {
          alert('预约成功！');
          track().pv('【指教音乐官网】成人声乐——成功提交信息');
          window.location.reload();
        }}
      />
      <CardList
        title="在Finger，发掘你的好声音"
        description={
          <>
            想要自信开口唱的个人主打歌吗？想要拥有超强音色控制力吗？想要燃炸全场的声音爆发力吗？
            <br />
            别让音乐永远停留在耳机里，Finger“灵魂歌者养成计划”让你距离“k歌之王”只有一套课的距离。
          </>
        }
        list={[
          {
            src: require('images/voice/adult/card-1.png'),
            itemStyle: { maxWidth: 400 },
            title: '五音不全，会不会学不会？',
            content: [
              <>
                正常情况下的五音不全的原理是：
                <br />
                对发声系统和呼吸系统的肌体不可控性
                <br />
                对音乐的听觉感知不够灵敏
              </>,
              <>
                为了掌控声音，就需要通过导师的耳朵帮助学员进行听感培养。
                <br />
                从而去对发声能力和吸肌能进行协调，这就是我们学习声乐的目的。
              </>,
              '正因如此，我们才需要进行演唱学习。',
            ],
          },
          {
            src: require('images/voice/adult/card-2.png'),
            itemStyle: { maxWidth: 400 },
            title: (
              <>
                我的声音不好听，我的音域窄
                <br />
                我怕唱不好、学不好
              </>
            ),
            content: [
              <>
                声音质感、音域都是可以进行美化和拓展的，
                <br />
                是时候改变你的思维模式了，相信自己一定拥有无限的潜力。
              </>,
              '在Finger，帮你发掘最好的声音。',
            ],
          },
          {
            src: require('images/voice/adult/card-3.png'),
            itemStyle: { maxWidth: 400 },
            title: '唱歌靠喊，不会“气功”？',
            content: [
              <>
                气息是扎实声音的基石，
                <br />
                气息的训练和你的肌肉能力息息相关
              </>,
              <>
                如何找到正确肌肉发力点并进行强化训练
                <br />
                离不开专业的引导和训练强度的调整
              </>,
              'Finger，做你声音校正器，带你循序渐进的开发气息力量，开发嗓音。',
            ],
          },
          {
            src: require('images/voice/adult/card-4.png'),
            itemStyle: { maxWidth: 400 },
            title: '唱歌无法投入感情，无法撼动人心？',
            content: [
              <>
                一首歌的处理是多方面的
                <br />
                从歌曲想要表达的思想感情、情绪感受、风格类型、伴奏结构分析
                <br />
                都是需要进行系统性的提升
              </>,
              'Finger专注唱商修炼，全方位提升你的音乐素养。',
            ],
          },
        ]}
      />
      <ScaleCarousel
        title="从“音乐小白”到“素人歌手”"
        description="满足全部阶段演唱水平需求"
        list={[
          {
            src: require('images/voice/adult/scale-1.png'),
            itemStyle: { maxWidth: 600 },
            title: '成人精品定制课',
            content: [
              '针对学员声音特性及个人风格，量身定制课程内容。',
              '根据学员学习需求，单独设立阶段性学习目标。全方位提高学员音乐综合素养。',
            ],
          },
          {
            src: require('images/voice/adult/scale-2.png'),
            itemStyle: { maxWidth: 600 },
            title: '成人零基础小班课',
            content: [
              '从零开始学习声乐，掌握基础乐理及基础发声原理，快速搭建发声系统概念，稳固与平衡基础发声能力，课程搭配数十首声音技巧训练歌曲，能够快速掌握基础歌曲演唱方法。',
              'Finger指教音乐中心的声乐教学团队来自国内多家音乐学府，成员均来自于上海音乐学院、中央音乐学院、浙江音乐学院、沈阳音乐学院、武汉音乐学院、四川音乐学院、南京艺术学院十大音乐院校。多位老师曾在各大音乐赛事《中国星》《唱响中国》等担任特邀评委。',
            ],
          },
        ]}
      />
      <AccCarousel
        title="课程体系"
        description={
          <>
            声乐教研团队匠心研制
            <br />
            遵循科学、有效率的声音训练模式
            <br />
            筛选并融合众多国内外声乐教学体系
            <br />
            打造适宜国人发声方式的基础声乐教学体系
          </>
        }
        list={[
          {
            itemStyle: { maxWidth: 1920 },
            src: require('images/voice/adult/acc-1.png'),
            title: '01/成人精品1v1定制课',
            desc: '视学员情况进行阶段定制',
            steps: [
              {
                label: 'lv.1',
                content:
                  '【发声基础入门】将基础气息、音准、节奏、声带闭合的训练作为教学目标，此阶段学员可以完成较为简单的演唱作品——民谣风格歌曲。',
              },
              {
                label: 'lv.2',
                content:
                  '【初级发声技巧】将平衡声音和气息运用，发展有效的共鸣，优化语音咬字，拓展音域作为教学目标，此阶段学员可以完成中级程度的演唱作品——抒情类型的流行歌曲。',
              },
              {
                label: 'lv.3',
                content:
                  '【进阶发声技巧】将优化声音饱满程度、气息强化、技巧性的声音情感作为教学目的，在这个阶段学员可以完成中高级级程度的演唱作品——爆发力强、音域跨度较大的流行歌曲。',
              },
              {
                label: 'lv.4',
                content:
                  '【音乐的情绪表达】从音乐背景及曲式分析，歌曲乐句语感入手，全方位提高学员音乐素养，强化歌唱情感。',
              },
              {
                label: 'lv.5',
                content:
                  '【音乐的艺术性及乐感提升】以歌曲风格作为教学载体，在歌曲中强调律动、咬字、气息高级运用，全方面提高音乐审美素养及音乐类型认知。',
              },
            ],
          },
          {
            itemStyle: { maxWidth: 1920 },
            src: require('images/voice/adult/acc-2.png'),
            title: '02/成人零基础一对多小班课',
            desc: '共四个阶段',
            steps: [
              {
                label: 'lv.1',
                content:
                  '掌握基本音高和节奏概念，简要了解声乐生理知识，培养科学的演唱习惯，规避发声误区。',
              },
              {
                label: 'lv.2',
                content: '深入了解更多有关气息运用知识，并有效运用至歌曲实践。',
              },
              {
                label: 'lv.3',
                content:
                  '深入训练发声技巧，打通发声通道，拓宽音域，训练声区之间的平滑过渡。',
              },
              {
                label: 'lv.4',
                content:
                  '强化音准模唱训练，同时强化听觉感知能力，提升对于音乐感情色彩变化的敏感度。',
              },
            ],
          },
        ]}
      />
      <TeachEnv
        title="教学环境"
        description="专业设计＆施工团队，打造杭城环境一流的音乐教育机构"
        list={[
          {
            step: '01',
            title: '校区环境',
            desc:
              '各大校区采用统一的现代装修格调，每一个角落都能带给您最佳感官体验。',
            previews: [
              {
                src: require('images/piano/adult/env-1-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-1-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-1-3.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '02',
            title: '一对一教室',
            desc:
              '一对一教室采用外国进口原装钢琴，良好的隔音环境打造独立的学习空间。',
            previews: [
              {
                src: require('images/piano/adult/env-2-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
              {
                src: require('images/piano/adult/env-2-2.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '03',
            title: '小班教室',
            desc:
              '小班教室使用全新进口的优质电钢与多功能屏幕进行教学，给学员营造良好的班级学习氛围。',
            previews: [
              {
                src: require('images/piano/adult/env-3-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
          {
            step: '04',
            title: '上课教材',
            desc: '独家研发的钢琴课、古典吉他课程、亲子音乐课均配有精美教材。',
            previews: [
              {
                src: require('images/piano/adult/env-4-1.jpg'),
                itemStyle: { maxWidth: 1920 },
              },
            ],
          },
        ]}
      />
      <BookFrom
        title="免费预约价值499元的成人零基础声乐课程"
        description="我们不会泄露你的个人信息，请放心"
        instrument="voice"
        customer="adult"
        placeholders={{
          schoolId: '选择校区',
          realName: '输入您的姓名',
          mobilePhone: '输入您的手机号',
          verifyCode: '输入您收到的验证码',
        }}
        onDirty={() => track().pv('【指教音乐官网】成人声乐——填写预约信息')}
        onSubmit={() => track().pv('【指教音乐官网】成人声乐——成功提交信息')}
      />
      <FixedBtn
        onClick={() => track().pv('【指教音乐官网】成人声乐——底部免费预约')}
      />
      <Footer showCopyRight={cr} />
    </div>
  );
};
